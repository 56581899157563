nav {
  align-items: center;
  background-color: $mainColor;
  display: flex;
  padding: 0px 12px;

  a {
    color: $backgroundColor;
    display: block;
    flex-grow: 0;
    font-weight: bold;
    padding: 14px 16px;
    text-align: left;
    text-decoration: none;

    &:hover {
      background-color: $backgroundColor;
      color: $mainColor;
    }

    &.current {
      color: $highlight-color;
    }

    &:hover.current {
      background-color: $backgroundColor;
      color: darken($highlight-color, 40%);
    }
  }

  .logo {
    margin-top: -1px;
    padding: 0px 16px;

    span {
      display: none;

      @media screen and (min-width: 640px) {
        display: inline;
        font-size: 1.5em;
        font-weight: bold;
        line-height: 46px;
        padding-left: 16px;
        vertical-align: top;
      }
    }

    #site-logo {
      filter: invert(80%) sepia(22%) saturate(16%) hue-rotate(229deg) brightness(94%) contrast(85%);
      margin-top: 4px;
    }

    &:hover #site-logo {
      filter: invert(15%) sepia(20%) saturate(411%) hue-rotate(196deg) brightness(89%) contrast(88%);
    }
  }

  .menuToggle {
    @media screen and (max-width: 1023px) {
      display: block;
      position: relative;
      z-index: 1;

      input {
        cursor: pointer;
        display: block;
        height: 32px;
        left: -5px;
        opacity: 0;
        position: absolute;
        top: -7px;
        width: 40px;
        z-index: 2;
      }

      span {
        background: $backgroundColor;
        border-radius: 3px;
        display: block;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        width: 33px;
        z-index: 1;

        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;

        &:first-child {
          transform-origin: 0% 0%;
        }

        &:nth-last-child(2) {
          transform-origin: 0% 100%;
        }
      }

      /*
       * Transform all the slices of hamburger
       * into a crossmark.
       */
      input:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: $backgroundColor;

        &:nth-last-child(3) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        &:nth-last-child(2) {
          transform: rotate(-45deg) translate(0, -1px);
        }
      }

      /*
       * And let's slide it in from the left
       */
      input:checked ~ .menu {
        transform: none;
        visibility: visible;
      }

      .menu {
        background: $mainColor;
        height: 100%;
        left: 0px;
        list-style-type: none;
        margin: 0;
        padding: 50px;
        padding-top: 125px;
        position: fixed;
        top: 0px;
        visibility: hidden;
        width: 100%;

        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */

        transform-origin: 0% 0%;
        transform: translate(100%, 0);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);

        a {
          font-size: 22px;
          padding: 10px 0;
        }
      }
    }

    @media screen and (min-width: 1024px) {
      input {
        display: none;
        visibility: hidden;
      }

      .menu {
        display: flex;

        .home {
          display: none;
          visibility: hidden;
        }
      }
    }
  }
}
