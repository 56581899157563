.highlight {

  pre {
    padding: 2em;
  }

  code {
    color: #bbbbbb;
  }

}
