html,
body {
  background-color: $backgroundColor;
  font-family: 'IBM Plex Mono', monospace;
}

a {
  color: darken($highlight-color, 25%);
  font-weight: bold;

  &:hover {
    color: darken($highlight-color, 25%);
  }

  &.footnote {
    text-decoration: none;
  }

  &.reversefootnote {
    text-decoration: none;
  }
}

.spacer {
  flex-grow: 1;
}

.card {
  background-color: lighten($backgroundColor, 15%);
  padding: 16px;
  margin: 16px;

  &:last-of-type {
    margin-bottom: 16px;
  }
}
