footer {
  align-items: center;
  background-color: $mainColor;
  color: $highlight-color;
  display: flex;
  font-weight: bold;
  justify-content: center;

  img {
    height: 32px;
    padding: 4px 0px;
    width: 32px;
  }

  img:last-of-type {
    padding-right: 8px;
  }

  a {
    text-decoration: none;
  }
}
