aside {
  display: none;
  visibility: hidden;

  &.home {
    display: block;
    visibility: visible;
  }

  .tagline {
    text-align: center;
  }

  .avatar {
    display: none;
  }

  .summary {
    text-align: justify;
  }

  .social {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 32px;

    a {
      background-repeat: no-repeat;
      background-size: cover;
      height: 32px;
      width: 32px;
    }

    .bitbucket {
      background-image: url("../images/bitbucket.svg");
    }

    .bitbucket-black {
      background-image: url("../images/bitbucket-b.svg");
    }

    .github {
      background-image: url("../images/github.svg");
    }

    .gitlab {
      background-image: url("../images/gitlab.svg");
    }

    .gitlab-black {
      background-image: url("../images/gitlab-b.svg");
    }

    .linkedin {
      background-image: url("../images/linkedin.svg");
    }

    .linkedin-black {
      background-image: url("../images/linkedin-b.svg");
    }

    .email {
      background-image: url("../images/mail.svg");
    }

    .twitter {
      background-image: url("../images/twitter.svg");
    }

    .twitter-black {
      background-image: url("../images/twitter-b.svg");
    }
  }

  @media screen and (min-width: 960px) {
    display: block;
    visibility: visible;

    .avatar {
      clip-path: circle(70px at center);
      display: block;
      margin-left: unset;
      margin-right: unset;
      order: 2;
      padding: 32px;
    }
  }
}

