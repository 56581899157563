.post {

  .metadata {
    color: darken($highlight-color, 25%);
    text-align: right;
  }

  .post-navigation {
    display: flex;
    padding-top: 32px;
  }
}
