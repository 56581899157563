html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 767px) and (min-width: 640px) {
    height: auto;
  }

  @media screen and (max-width: 1023px) and (min-width: 768px) and (orientation: landscape) {
    height: auto;
  }
}

nav,
footer {
  flex-shrink: 0;
  min-height: 48px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

main {
  flex-grow: 1;
  overflow: auto;
}

footer {
  margin-top: auto;
}

#left {
  display: none;
  visibility: hidden;
}

@media screen and (min-width: 960px) {
  .container {
    flex-wrap: nowrap;
  }

  aside {
    flex-shrink: 0;
    height: calc(100vh - 96px - 64px);
    max-width: 272px;
  }
}

@media screen and (min-width: 1296px) {
  #left {
    display: block;
    flex-grow: 1;
    visibility: visible;
  }

  main div.card {
    width: 960px;
  }
}
